import { DisableBadge } from 'hooks/service/useVisitHourBottomSheet'
import { useAppSelector } from 'hooks/useReduxHook'
import { setServiceHour, ServiceHourType } from 'modules/features/service/serviceSlice'
import { BottomSheet, CheckOptionListRow, SelectBox, TypoC1 } from 'ohds-web'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

export type ServiceHourSelectBoxProps = {
  autoOpen: boolean
  label: string
  value?: string
  serviceHourOptions: Readonly<ServiceHourType[]>
  storeType: string
}

export default function ServiceHourSelectBox({
  autoOpen,
  label,
  value,
  serviceHourOptions,
  storeType,
}: ServiceHourSelectBoxProps) {
  const dispatch = useDispatch()
  const { serviceHour, serviceDayValues } = useAppSelector((state) => state.service)

  const [isHourSelectOpen, setIsHourSelectOpen] = useState<boolean>(false)

  const openOption = () => setIsHourSelectOpen(true)
  const closeOption = () => setIsHourSelectOpen(false)

  useEffect(() => {
    if (autoOpen) setIsHourSelectOpen(true)
  }, [autoOpen])

  const serviceTimeMinuteFor전담 = serviceDayValues.length >= 5 ? 60 : 90

  const handleSelectBoxClick = () => {
    openOption()
  }

  const handleBottomSheetClose = () => {
    closeOption()
  }

  const handleOptionClick = (selectedVisitHour: ServiceHourType) => {
    // 저녁시간 마감 처리
    if (selectedVisitHour >= 19) return false
    closeOption()
    dispatch(setServiceHour(selectedVisitHour))
  }

  return (
    <SelectBox
      label={label}
      value={value}
      onSelectBoxClick={handleSelectBoxClick}
      BottomSheet={
        <StyledBottomSheet
          open={isHourSelectOpen}
          title="관리 시작 시간을 선택해 주세요"
          subTitle={`관리는 평균 ${
            storeType === '스터디카페'
              ? 90
              : storeType === '전담게이트'
              ? serviceTimeMinuteFor전담
              : storeType === '탁구발전소24'
              ? 40
              : 60
          }분 소요됩니다.`}
          onClose={handleBottomSheetClose}
        >
          {serviceHourOptions.map((visitHourOption) => (
            <CheckOptionListRow
              key={visitHourOption}
              text={`${visitHourOption}시`}
              checked={serviceHour === visitHourOption}
              onClick={() => handleOptionClick(visitHourOption)}
              // 저녁시간 마감 처리
              disabled={visitHourOption >= 19}
              Right={
                visitHourOption >= 19 ? (
                  <DisableBadge>
                    <TypoC1 color="gray600" text="마감" />
                  </DisableBadge>
                ) : undefined
              }
            />
          ))}
        </StyledBottomSheet>
      }
    />
  )
}

const StyledBottomSheet = styled(BottomSheet)`
  header {
    p:last-child {
      font-weight: bold;
      color: var(--brand300);
    }
  }

  @media only screen and (min-width: 768px) {
    width: 50vw;
    position: fixed;
    left: 50%;
  }
`
