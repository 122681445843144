import { memo, useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { calcTotalDate, checkIsHoliday, getDay, getServicePeriodFormatter2, weekArr } from '../../utils/common/calendar'
import { SelectedDateType } from '../../utils/common/time'
import {
  PickerWrapper,
  InfoContainer,
  Info,
  Calendar,
  CalendarHeader,
  CalendarInfo,
  CalendarBody,
  CalendarDateText,
} from '../common/FirstDatePicker/FirstDatePicker'
import Icon from '../Icon'
import Tooltip from 'components/common/Tooltip/Tooltip'

export type BvsViewProps = {
  isSubscribePayment?: boolean
  firstDate: SelectedDateType
  totalReservationDate: SelectedDateType[]
  extraDates: SelectedDateType[]
  forCheck?: boolean
  forPending?: boolean
}
const BvsViewer = ({
  isSubscribePayment,
  firstDate,
  totalReservationDate,
  extraDates,
  forCheck,
  forPending,
}: BvsViewProps) => {
  const [year, setYear] = useState<number>(firstDate.year)
  const [month, setMonth] = useState<number>(firstDate.month)
  const [totalDate, setTotalDate] = useState<number>(0)

  useEffect(() => {
    setTotalDate(calcTotalDate(year, month))
  }, [month, year])

  const onLeftButtonClick = useCallback(() => {
    if (year === firstDate.year && month === firstDate.month) return
    if (month === 1) {
      setYear(year - 1)
      setMonth(12)
    } else setMonth(month - 1)
  }, [firstDate, month, year])

  const onRightButtonClick = useCallback(() => {
    if (month === 12) {
      setYear(year + 1)
      setMonth(1)
    } else setMonth(month + 1)
  }, [month, year])

  if (!totalReservationDate || totalReservationDate.length === 0) return null

  // 1일의 getDay값
  const firstDayNum = getDay(year, month, 1)
  // 첫 주의 달력에서의 고를 수 없는 빈공간
  const firstWeekBlankNum = firstDayNum === 0 ? 6 : firstDayNum - 1

  const { year: firstYear, month: firstMonth, date: firstDay, time } = totalReservationDate[0]
  const { year: lastYear, month: lastMonth, date: lastDay } = totalReservationDate[totalReservationDate.length - 1]

  const firstDateFormat = `${firstYear}.${firstMonth}.${firstDay}`
  const lastDateFormat = `${lastYear}.${lastMonth}.${lastDay}`

  const convertedTime = time.includes('오후') ? Number(time.replace('오후 ', '')) + 12 : time.replace('오전', '')

  return (
    <>
      <PickerWrapper>
        <InfoContainer>
          <Info>
            <span>첫 출근일</span>
            <div>
              <p>{firstDate.date}일</p>
            </div>
          </Info>
          <Info>
            <span>서비스 유형</span>
            {isSubscribePayment ? (
              <Tooltip
                triggerContent={<p>자동 결제 상품</p>}
                tooltipContent={<span>4주에 한 번 씩 등록된 결제 수단으로 자동 결제됩니다.</span>}
              />
            ) : (
              <p>일반 결제 상품</p>
            )}
          </Info>
        </InfoContainer>
        <Calendar>
          <CalendarHeader>
            <button onClick={onLeftButtonClick}>
              <Icon name="grayLeftArrowS" />
            </button>
            <CalendarInfo>
              <p>
                {year}년 {month}월
              </p>
              <p>
                서비스 기간&nbsp;&nbsp;:&nbsp;&nbsp;
                {getServicePeriodFormatter2(firstDateFormat, lastDateFormat)}
              </p>
            </CalendarInfo>
            <button onClick={onRightButtonClick}>
              <Icon name="grayRightArrowS" />
            </button>
          </CalendarHeader>
          <CalendarBody>
            {weekArr.map((day, i) => (
              <div key={day}>
                <CalendarDateText isDay={true} isActive={true}>
                  {day}
                </CalendarDateText>
              </div>
            ))}
            {Array.from({ length: firstWeekBlankNum }).map((_, i) => (
              <div key={i} />
            ))}
            {Array.from({ length: totalDate }).map((_, i) => {
              const isClicked = !!totalReservationDate.find(
                (date) => date.year === year && date.month === month && date.date === i + 1
              )

              return (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <StyledCalendarDateText
                    isDay={false}
                    isActive
                    isClicked={isClicked}
                    isHoliday={checkIsHoliday(year, month, i + 1)}
                  >
                    {i + 1}
                  </StyledCalendarDateText>
                  {isClicked && <TimeText>{convertedTime}</TimeText>}
                </div>
              )
            })}
          </CalendarBody>
        </Calendar>
      </PickerWrapper>
    </>
  )
}

const StyledCalendarDateText = styled(CalendarDateText)<{ isHoliday?: boolean }>`
  cursor: default;

  ${({ isClicked }) =>
    isClicked &&
    css`
      background-color: var(--brand300);
    `}
  ${({ isHoliday }) =>
    isHoliday &&
    css`
      color: #ff0000;
    `}
`

const TimeText = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 21px;
  color: var(--brand300);
`

export default memo(BvsViewer)
