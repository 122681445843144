import { SelectedDateType } from './time'
import twoDigitsNumConverter from './twoDigitsNumConverter'

export const calcIsLeapYear = (year: number) => {
  if (year % 100 !== 0 && (year % 4 === 0 || year % 400 === 0)) return true
  return false
}

export const calcIsThirtyFirst = (month: number) => {
  if ((month < 8 && month % 2 === 1) || (month >= 8 && month % 2 === 0)) return true
  return false
}

export const calcTotalDate = (year: number, month: number) => {
  if (month === 2) {
    if (calcIsLeapYear(year)) return 29
    return 28
  } else {
    if (calcIsThirtyFirst(month)) return 31
    return 30
  }
}

export const getDay = (year: number, month: number, date: number) => {
  return new Date(year, month - 1, date).getDay()
}

export const weekArr = ['월', '화', '수', '목', '금', '토', '일']
export const weekDayArr = [...weekArr.slice(0, 5)]

const weekForDayOfTheWeek = ['일', '월', '화', '수', '목', '금', '토'] as const

export const getDayOfTheWeek = (year: number, month: number, date: number) => {
  const dayNum = new Date(year, month - 1, date).getDay()

  return weekForDayOfTheWeek[dayNum]
}

export const holidayArr = [
  {
    month: 1,
    date: 1,
  }, // 새해
  {
    month: 3,
    date: 1,
  }, // 삼일절
  {
    month: 5,
    date: 5,
  }, // 어린이날
  {
    month: 6,
    date: 6,
  }, // 현충일
  {
    month: 8,
    date: 15,
  }, // 광복절
  {
    month: 10,
    date: 3,
  }, // 개천절
  {
    month: 10,
    date: 9,
  }, // 한글날
  {
    month: 12,
    date: 25,
  }, // 크리스마스
  // 매년 변동 성 있는 공휴일들
  {
    year: 2024,
    month: 2,
    date: 9,
  }, // 설날
  {
    year: 2024,
    month: 2,
    date: 10,
  }, // 설날
  {
    year: 2024,
    month: 2,
    date: 11,
  }, // 설날
  {
    year: 2024,
    month: 2,
    date: 12,
  }, // 설날 대체공휴일
  {
    year: 2024,
    month: 4,
    date: 10,
  }, // 제 22대 국회의원 선거
  {
    year: 2024,
    month: 5,
    date: 6,
  }, // 어린이날 대체공휴일
  {
    year: 2024,
    month: 5,
    date: 15,
  }, // 부처님 오신 날
  {
    year: 2024,
    month: 9,
    date: 16,
  }, // 추석
  {
    year: 2024,
    month: 9,
    date: 17,
  }, // 추석
  {
    year: 2024,
    month: 9,
    date: 18,
  }, // 추석
]

export const checkIsHoliday = (year: number, month: number, date: number) => {
  const totalDate = calcTotalDate(year, month)
  const isOverMonth = totalDate < date

  const validYear = isOverMonth ? calcNextYear(year, month) : year
  const validMonth = isOverMonth ? calcNextMonth(month) : month
  const validDate = isOverMonth ? date - totalDate : date

  const holiday = holidayArr.find(
    ({ year: holidayYear, month: holidayMonth, date: holidayDate }) =>
      (holidayYear ? holidayYear === validYear : true) && holidayMonth === validMonth && holidayDate === validDate
  )

  return !!holiday
}

export const checkIsWeekend = (year: number, month: number, date: number) => {
  return new Date(year, month - 1, date).getDay() === 6 || new Date(year, month - 1, date).getDay() === 0
}

export const calcServicePeriod = ({
  year,
  month,
  date,
  totalWeek = 4,
}: {
  year: number
  month: number
  date: number
  totalWeek?: number
}) => {
  const totalDate = calcTotalDate(year, month)

  return `${twoDigitsNumConverter(month)}.${twoDigitsNumConverter(date)} ~ ${
    date + 7 * totalWeek - 1 - totalDate - (getDay(year, month, date) === 1 ? 2 : 0) > 0
      ? month === 12
        ? `${twoDigitsNumConverter(1)}.${twoDigitsNumConverter(
            date + 7 * totalWeek - 1 - totalDate - (getDay(year, month, date) === 1 ? 2 : 0)
          )}`
        : `${twoDigitsNumConverter(month + 1)}.${twoDigitsNumConverter(
            date + 7 * totalWeek - 1 - totalDate - (getDay(year, month, date) === 1 ? 2 : 0)
          )}`
      : `${twoDigitsNumConverter(month)}.${twoDigitsNumConverter(
          date + 7 * totalWeek - 1 - (getDay(year, month, date) === 1 ? 2 : 0)
        )}`
  }`
}

/**
 * 서비스 신청 시 서비스 기간 텍스트 빌더
 * @param firstDate 첫 출근일
 * @param totalWeek 서비스의 총 주
 * @returns 서비스 기간 텍스트
 */

export const buildServicePeriodText = (
  { year: firstYear, month: firstMonth, date: firstDate }: { year: number; month: number; date: number },
  totalWeek: number
) => {
  const endDateClass = new Date(firstYear, firstMonth - 1, firstDate + 7 * totalWeek - 1)
  const endYear = endDateClass.getFullYear()
  const endMonth = endDateClass.getMonth() + 1
  const endDate = endDateClass.getDate()

  return `${firstYear}.${twoDigitsNumConverter(firstMonth)}.${twoDigitsNumConverter(firstDate)} ~ ${
    endYear !== firstYear ? `${endYear}.` : ''
  }${twoDigitsNumConverter(endMonth)}.${twoDigitsNumConverter(endDate)}`
}

export const calcNextYear = (year: number, month: number) => (month === 12 ? year + 1 : year)
export const calcNextMonth = (month: number) => (month === 12 ? 1 : month + 1)

export const calcBlankOfMonth = (year: number, month: number) => {
  const dayNum = getDay(year, month, 1)
  return dayNum === 0 ? 6 : dayNum - 1
}

export const calcBlankOfWeek = (year: number, month: number, date: number) => {
  const dayNum = getDay(year, month, date)
  return dayNum === 0 ? 6 : dayNum - 1
}

export const getNow = () => {
  const now = new Date()

  return {
    presentYear: now.getFullYear(),
    presentMonth: now.getMonth() + 1,
    presentDate: now.getDate(),
  }
}

export type GetDateFormatReturns = {
  year: number
  month: number
  date: number
}

export const getDateFormat = (day: Date): GetDateFormatReturns => {
  return {
    year: day.getFullYear(),
    month: day.getMonth() + 1,
    date: day.getDate(),
  }
}

export const getServicePeriodFormatter2 = (startDate: string, endDate: string) => {
  const firstDay = new Date(startDate)
  const lastDay = new Date(endDate)

  const firstMonth = firstDay.getMonth() + 1
  const firstDate = firstDay.getDate()

  const lastMonth = lastDay.getMonth() + 1
  const lastDate = lastDay.getDate()

  return `${twoDigitsNumConverter(firstMonth)}.${twoDigitsNumConverter(firstDate)} ~ ${twoDigitsNumConverter(
    lastMonth
  )}.${twoDigitsNumConverter(lastDate)}`
}

export const getServicePeriodFormatter = (startDate: string, endDate: string) => {
  return `${getDateFormatToYMD(new Date(startDate))} ~ ${getDateFormatToYMD(new Date(endDate))}`
}

export const getDateFormatToYMD = (day: Date) => {
  const { year, month, date } = getDateFormat(day)

  return `${year}년 ${month}월 ${date}일`
}
